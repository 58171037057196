import React, {useEffect, useState} from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import visual from '../images/up.jpg'
import pattern from '../images/pattern-contact.jpg'
import Thanks from '../svg/thanks-circle.svg'
import PageHeader from '../components/PageHeader'
import Button from '../components/Button'
import styled from 'styled-components'
import {LeftSection, RightSection, PageWrapper, Row, Span, Title, Flex} from '../components/UI'
import FormItem from '../components/FormItem'
import _ from 'lodash'
import {breakpoints} from '../utils/helper'
import axios from 'axios'
import {OutboundLink} from 'gatsby-plugin-gtag'
import {apiURL} from '../utils/helper'
import useApi from "../utils/useApi"
import UpClubPerks from '../components/UpClubPerks'

const CustomTitle = styled(Title)`
    color: ${props => props.theme.colors.white};
    
    b {
        color: ${props => props.theme.colors.red};    
    }
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    margin-top: 36px;
    
    button {
        width: 128px;
        margin-top: 48px;
    }
    
    @media ${breakpoints.desktop} {
        padding-left: 54px;
        margin-top: 0;
    }
`

const Terms = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`

const Label = styled.label`
    margin-left: 12px;
    font-size: 0.75rem;
    color: ${props => props.error ? props.theme.colors.red : props.theme.colors.black};
`

const Link = styled(OutboundLink)`
    color: ${props => props.error ? props.theme.colors.red : props.theme.colors.black};
`

const MyRow = styled(Row)`
    margin-bottom: 90px;
    flex-direction: column;
    .text {
        width: 100%;
    }
    @media ${breakpoints.desktop} {
        flex-direction: row;    
    }
`

const ThanksBox = styled(Flex)`
    align-items: flex-start;
    justify-content: center;
    //height: 240px;
    width: 80%;
    flex-direction: column;
    
    svg {
        flex-shrink: 0;
        margin-bottom: 42px;
    }
    
    h2 {
        text-align: left;
        font-size: 2rem;
        line-height: 2.25rem;
        margin-bottom: 24px;
        color: ${props => props.theme.colors.red};
    }
`

const BusinessCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 24px;
    span {
        line-height: 1.5rem;
    }
    
    button {
        margin-top: 18px;
    }
`

const UPPage = ({location}) => {
    const {createProfile} = useApi()
    const [statusCode, setStatusCode] = useState(null)
    const [statusCodeCard, setStatusCodeCard] = useState(null)
    const [showError, setShowError] = useState(false)
    const [formItems, setFormItems] = useState({
        first_name: '',
        last_name: '',
        email: '',
        license: '',
        terms: false
    })

    useEffect(() => {
        if (statusCodeCard >= 400 && statusCodeCard <= 499) {
            alert('Er is een fout opgetreden. Probeer aub opnieuw')
            setStatusCodeCard(null)
        }
    }, [statusCodeCard])

    const handleChange = (e) => {
        setShowError(false)
        setFormItems({
            ...formItems,
            [e.target.name]: e.target.name !== 'terms' ? e.target.value : e.target.checked
        })
    }

    const hasEntry = (val) => !_.isEmpty(val)

    const handleSubmit = (e) => {
        e.preventDefault()
        signup(_.get(formItems, 'first_name'),
            _.get(formItems, 'last_name'),
            _.get(formItems, 'email'),
            _.get(formItems, 'license'),
            _.get(formItems, 'terms'))
    }

    const signup = async (firstname, lastname, email, license, terms) => {
        setShowError(true)

        const valid = validEmail(email)
            && hasEntry(firstname)
            && hasEntry(lastname)
            && terms

        if (valid) {
            try {
                const res = await axios.post(
                    apiURL('/profiles'),
                    {first_name: firstname, last_name: lastname, email: email, license: license})
                setShowError(false)
                setStatusCode(_.get(res, 'status'))
            } catch ({response}) {
                setStatusCode(_.get(response, 'status', 500))
            }
        }
    }

    const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

    const emailError = () => {
        const email = _.get(formItems, 'email')
        switch (true) {
            case _.isEmpty(email):
                return 'Er is geen e-mailadres ingevuld.'
            case !validEmail(email):
                return 'Het ingevulde e-mailadres is ongeldig'
            default:
                return ''
        }
    }

    const resendLoyaltyCard = async () => {
        try {
            const res = await axios.post(
                apiURL('/profiles/resend'),
                {email: _.get(formItems, 'email')})
            //console.log(res)
            setStatusCodeCard(_.get(res, 'status'))
        } catch ({response}) {
            setStatusCodeCard(_.get(response, 'status', 500))
        }
    }

    const Result = () => {
        //console.log(statusCode)
        switch (true) {
            case statusCode <= 299:
                return (
                    <>
                        <Thanks/>
                        <h2>Bedankt voor je aanmelding!</h2>
                        <p>
                            Bevestig je aanmelding in de mail dan spaar je binnenkort met elke wasbeurt
                            automatisch voor korting of een upgrade op je wasbeurt. We houden je op de hoogte.
                        </p>
                    </>
                )
            case statusCode === 409:
                return (
                    <>
                        <Thanks/>
                        <h2>Je bent al lid!</h2>
                        <p>
                            We zien dat je al bent aangemeld voor de UP Club.
                            <br/>
                            <br/>
                            Wil je de UP Club membercard opnieuw
                            ontvangen? Klik dan op de onderstaande knop.
                            <br/>
                            <br/>
                        </p>
                        {
                            (statusCodeCard === 200)
                                ? <span>Gelukt! Je pas is verstuurd.</span>
                                : <Button onClick={() => resendLoyaltyCard()}>Verstuur mijn membercard</Button>
                        }
                    </>
                )
            default:
                return (
                    <>
                        <h2>Er is een fout opgetreden.</h2>
                        <p>Probeer aub opnieuw.</p>
                    </>
                )
        }
    }

    return (
        <Layout location={location}>
            <SEO title="UP Club" description="De UP Club is een gratis lidmaatschap voor de hele wasfamilie."/>
            <PageHeader image={visual} backgroundColor="#333333" textColor="#FFFFFF" imageWidth="520px"
                        pattern={pattern}>
                <CustomTitle>
                    Word lid van<br/>de UP Club<br/>en profiteer!
                </CustomTitle>
            </PageHeader>
            <PageWrapper>
                <MyRow>
                    <LeftSection>
                        <Span>
                            Als lid van de UP Club spaar je gratis voor korting of een upgrade op je wasbeurt.
                            Bij elke wassing spaar je stamps en bij 25 stamps ontvang je een gratis wasprogramma!
                            <UpClubPerks/>
                            <OutboundLink href="https://club.upcarwash.nl/registreren" target="_blank" rel="noopener"><Button>Meld je aan</Button></OutboundLink>
                            <BusinessCont>
                                        <span>Wil je zakelijk wassen bij UP? Stuur dan een e-mail naar <OutboundLink href={"mailto:service@upcarwash.nl"} target="_blank" rel="noopener">
                                                service@upcarwash.nl
                                            </OutboundLink>. Heb je al een zakelijke account? Log in via de onderstaande knop.</span>
                                <OutboundLink href="https://www.x10spin.com/org/upcarwash/login" target="_blank" rel="noopener"><Button color={Button.styles.BLACK}>UP Club Business</Button></OutboundLink>
                            </BusinessCont>
                        </Span>
                    </LeftSection>
                    <RightSection>



                    </RightSection>
                </MyRow>
            </PageWrapper>
        </Layout>
    )
}

export default UPPage
