import React from 'react'
import _ from 'lodash'
import {createContext, useReducer, useState} from 'react'
import axios from 'axios'

const initialState = {
    profiles: {
        items: [],
        token: null,
    },
    profile: null,
    message: null
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'CREATE_PROFILE':
            return {
                ...state,
                profiles: {
                    ..._.get(state, 'profiles', {}),
                    items: _.concat(
                        _.get(state, 'profiles.items', []),
                        _.get(action, 'data', {}) // created profile
                    ),
                    token: null,
                }
            }
        case 'LIST_PROFILES':
            const { profiles, token } = _.get(action, 'payload', {})
            return {
                ...state,
                profiles: {
                    ..._.get(state, 'profiles', {}),
                    items: profiles,
                    token,
                }
            }
        case 'FETCH_PROFILE':
            return {
                ...state,
                profile: _.head(_.get(action, 'payload.profiles', {}))
            }
        case 'UPDATE_PROFILE':
            console.log(action)
            return {
                ...state,
                profile: _.get(action, 'payload', {})
            }
        case 'DELETE_PROFILE':
        case 'SEND_LOYALTY_CARD':
            return {
                ...state,
                message: _.head(_.get(action, 'payload', {}))
            }
        default:
            return state
    }
}

const APIContext = createContext({})

//const endpoint = 'https://api.dev.upcarwash.nl/'
//const baseURL = 'https://7jodo3tu2e.execute-api.eu-west-1.amazonaws.com/prod/'
const baseURL = 'https://c80zhckxoj.execute-api.eu-west-1.amazonaws.com/prod/'

export const APIProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [loading, setLoading] = useState(false)
    //const { user } = useAuth()

    // HELPERS
/*    const getHeaders = (method, action, payload = null) => {
        const awsDate = (new Date()).toISOString().replace(/[:-]|\.\d{3}/g, '')

        const headers = {
            'X-Amz-Date': awsDate,
            'Host': '7jodo3tu2e.execute-api.eu-west-1.amazonaws.com',
            'Content-Type': 'application/json',
        }

        const signed = signedHeaders(
            {
                headers: headers,
                method: method,
                url: baseURL + action,
                body: payload ? JSON.stringify(payload) : ''},
            {
                ..._.pick(_.get(user, 'credentials'), ['accessKeyId', 'secretAccessKey', 'sessionToken']),
                region: 'eu-west-1',
                service: 'execute-api',
            })

        return {
            ..._.omit(_.get(signed, 'headers'), 'Host'),
        }
    }*/

    const doQuery = async (type, method, action, onError, payload = null) => {
        // const headers = getHeaders(method, action, payload)
        //
        // if (!headers) {
        //     return null
        // }

        setLoading(true)
        try {
            // @ts-ignore
            const resp = await axios({ method: method, url: baseURL + action, data: payload, headers: {}})
            dispatch({
                type: type,
                payload: resp.data
            })
        } catch (resp) {
            onError(resp)
        }
        setLoading(false)
    }

    const listProfiles = () => doQuery(
        'LIST_PROFILES',
        'get',
        'profiles',
        response => {
            console.error('failed list profiles:', response.statusText)
        }
    )

    const fetchProfileByEmail = (email) => fetchProfile(`email=${email}`)
    const fetchProfileByName = (name) => fetchProfile(`name=${name}`)
    const fetchProfileById = (id) => fetchProfile(`id=${id}`)
    const fetchProfileByCardId = (cardId) => fetchProfile(`card_id=${cardId}`)

    const fetchProfile = (payload) => doQuery(
        'FETCH_PROFILE',
        'get',
        `profiles?${payload}`,
        response => {
            //console.error('failed list profiles:', response.statusText)
            console.log(response.data);
            console.log(response.status);
            console.log(response.statusText);
            console.log(response.headers);
            console.log(response.config);
        },
    )

    const createProfile = (payload) => doQuery(
        'CREATE_PROFILE',
        'post',
        'profiles',
        e => {
            console.error('failed creating profile:', e)
        },
        payload
    )

    const updateProfile = (id, payload) => doQuery(
        'UPDATE_PROFILE',
        'put',
        `profiles/${id}`,
        e => {
            console.error('failed updating profile:', e)
        },
        payload
    )

    const removeProfile = (payload) => doQuery(
        'DELETE_PROFILE',
        'delete',
        `profiles/${payload}`,
        response => {
            console.error('failed deleting profile:', response.statusText)
        }
    )

    const sendCard = (payload) => doQuery(
        'SEND_LOYALTY_CARD',
        'post',
        'profiles/resend',
        e => {
            console.error('failed sending card:', e)
        },
        payload
    )

    return (
        <APIContext.Provider
            value={{
                ...state,
                loading,
                listProfiles,
                createProfile,
                updateProfile,
                removeProfile,
                fetchProfileByEmail,
                fetchProfileByName,
                fetchProfileById,
                fetchProfileByCardId,
                sendCard
            }}>
            {children}
        </APIContext.Provider>
    )
}

export default APIContext
